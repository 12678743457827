import { CategoryKeys } from "../data/category.enum";
import { Roles } from "./roles.enum";

export const RolePermissions = {
  [Roles.TwcReportsUser]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.REPORTING,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcGroupReports]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.REPORTING,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcStoreOwner]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.MANAGE_STORES,
    CategoryKeys.MESSAGE_TEMPLATES,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.QUOTE_DASHBOARD,
    CategoryKeys.QUOTE_REPORT,
    CategoryKeys.QUOTE_CONVERSION_REPORT,
    CategoryKeys.INTEGRATIONS,
    CategoryKeys.REPORTING,
    CategoryKeys.USER_ADMINISTRATION,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.NOTIFICATIONS,
    CategoryKeys.APP_CONFIGURATIONS,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcGroupOwner]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.MANAGE_STORES,
    CategoryKeys.MESSAGE_TEMPLATES,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.QUOTE_DASHBOARD,
    CategoryKeys.QUOTE_REPORT,
    CategoryKeys.QUOTE_CONVERSION_REPORT,
    CategoryKeys.INTEGRATIONS,
    CategoryKeys.REPORTING,
    CategoryKeys.USER_ADMINISTRATION,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.NOTIFICATIONS,
    CategoryKeys.APP_CONFIGURATIONS,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcStoreUser]: [ 
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.REPORTING,
    CategoryKeys.USER_ADMINISTRATION,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.LOGOUT,
  ],
  [Roles.TwcGroupUser]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.REPORTING,
    CategoryKeys.USER_ADMINISTRATION,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.LOGOUT,
  ],
  [Roles.StoreOwner]: [...Object.values(CategoryKeys)],
  [Roles.TwcSuperUser]: [...Object.values(CategoryKeys)],
  [Roles.OldAuthMethod]: [
    CategoryKeys.WISHLIST_DASHBOARD,
    CategoryKeys.MANAGE_STORES,
    CategoryKeys.MESSAGE_TEMPLATES,
    CategoryKeys.STORE_STAFF_DASHBOARD,
    CategoryKeys.QUOTE_DASHBOARD,
    CategoryKeys.QUOTE_REPORT,
    CategoryKeys.QUOTE_CONVERSION_REPORT,
    CategoryKeys.SUBSCRIPTION,
    CategoryKeys.INTEGRATIONS,
    CategoryKeys.NOTIFICATIONS,
    CategoryKeys.REPORTING,
    CategoryKeys.POS_CONFIG,
    CategoryKeys.DOCUMENTATION,
    CategoryKeys.LOGOUT,
    CategoryKeys.USER_ADMINISTRATION,
  ],
};
